import React from 'react'
import PropTypes from 'prop-types'

import resume from '../assets/docs/matthew_an_2020.pdf'

const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="logo">
      <span className="icon fa-code"></span>
    </div>
    <div className="content">
      <div className="inner">
        <h1>Matthew An</h1>
        <p>
          Full-stack developer @{' '}
          <a href="https://www.xero.com" target="_blank">
            Xero
          </a>
        </p>
      </div>
    </div>
    <nav>
      <ul>
        <li>
          <a
            href="javascript:;"
            onClick={() => {
              props.onOpenArticle('work')
            }}
          >
            Work
          </a>
        </li>
        <li>
          <a href={resume}>Resume</a>
        </li>
        <li>
          <a target="blank" href="https://www.github.com/mtthwn">
            GitHub
          </a>
        </li>
        <li>
          <a href="https://www.linkedin.com/in/mtthwn/" target="blank">
            LinkedIn
          </a>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
