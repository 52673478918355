import React from 'react'
import PropTypes from 'prop-types'

import ads from '../images/ADS_Repo.jpg'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About</h2>
          <p>
            Hi! I'm Matthew - a technical writer turned full-stack developer.
          </p>
          <p>
            I have experience in a variety of industries and projects, ranging
            from building a fun little typing game to using AI to fundamentally
            change the candidate screening process with{' '}
            <a href="https://knockri.com/" target="_blank">
              Knockri
            </a>
            . Please take a look through my work to see examples of the projects
            I've worked on, and feel free to connect with me on LinkedIn.
          </p>
          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h1 className="major">Portfolio</h1>
          <section>
            <h2>
              {/* <a href="http://40.78.146.198:3000/" target="blank"> */}
              TypeBeast
              {/* </a> */}
            </h2>
            <h4>
              <a target="_blank" href="https://github.com/mtthwn/typebeast">
                GitHub Repo
              </a>
            </h4>
            <span className="image main">
              <img
                src="https://raw.githubusercontent.com/my2an/typebeast/master/client/docs/race.jpg"
                alt="typebesat game"
              />
            </span>{' '}
            <p>
              TypeBeast is a modern take on the classic typing game. For our
              final project at Lighthouse Labs, my team and I built TypeBeast
              using the MERN stack. The project also uses websockets for
              multiplayer functionality, and has an in-game rewards system that
              allows registered users to buy and use cars with currency that is
              earned after completing online games.
            </p>
            <h5>Tech used</h5>
            <ul>
              <li>
                MERN (
                <a href="https://www.mongodb.com/" target="_blank">
                  MongoDB
                </a>{' '}
                with{' '}
                <a href="https://mongoosejs.com/" target="_blank">
                  Mongoose
                </a>
                ,{' '}
                <a href="https://expressjs.com/" target="_blank">
                  Express
                </a>
                ,{' '}
                <a href="https://reactjs.org/" target="_blank">
                  React
                </a>
                ,{' '}
                <a href="https://nodejs.org/en/" target="_blank">
                  Node.js
                </a>
                )
              </li>
              <li>
                <a href="https://socket.io/" target="_blank">
                  Socket.io
                </a>
              </li>
              <li>
                <a href="https://www.docker.com/" target="_blank">
                  Docker
                </a>
              </li>
              <li>Microsoft Azure</li>
            </ul>
          </section>

          <section>
            <h2>Booster-ADS</h2>
            <span className="image main">
              <img src={ads} alt="ADS" />
            </span>{' '}
            <p>
              BOOSTER-ADS (Assumption Development System) is a web app built for
              actuarial work concerning the management and governance over data
              used in assumption calculations. ADS has been adopted by a number
              of South Korea's top life insurance companies, and is expanding
              throughout East Asia due to the increasing complexity of upcoming
              industry-related regulatory standards.
            </p>
            <p>
              We built ADS using NodeJs with server-side rendered HTML/CSS/JS
              files using jQuery for client-API communications. I primarily
              built out the administrative tools, and helped implement multiple
              new features into the application. I also wrote scripts for
              testing and scheduling CRON jobs, as well as writing database
              configurations and schemas. During the project, I also provided
              on-site consulting for our clients and helped them adopt ADS into
              their workflows.
            </p>
            <h5>Tech used</h5>
            <ul>
              <li>MongoDB with Mongoose</li>
              <li>Express.js</li>
              <li>Node.js</li>
              <li>
                <a
                  href="https://www.tableau.com/products/server"
                  target="_blank"
                >
                  Tableau Server
                </a>
              </li>
              <li>
                <a
                  href="https://www.npmjs.com/package/oracledb"
                  target="_blank"
                >
                  OracleDB
                </a>
              </li>
              <li>C# .NET 4.5</li>
              <li>RHEL</li>
            </ul>
          </section>

          <section>
            <h2>Add4</h2>
            <h4>
              <a target="_blank" href="https://github.com/mtthwn/add4">
                GitHub Repo
              </a>
            </h4>
            <p>
              Add4 is an NPM package that I created to learn about contributing
              to packages on NPM. Not the most serious project, but a fun
              experiment that helped me understand how NPM packages are created
              and published, as well as understand how updates are made and
              proper versioning practices for creating my own future NPM
              contributions.
            </p>
            <h5>Tech used</h5>
            <ul>
              <li>Node.js</li>
              <li>NPM</li>
              <li>Javascript</li>
            </ul>
          </section>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
